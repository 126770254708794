import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class MenuItem extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <li className="factMenu" ><a href={this.props.href}>{this.props.text} </a></li>
        )
    }
}
export class FactsMenu extends Component {
    static displayName = FactsMenu.name;

    constructor(props) {
        super(props);
        this.state = {
            data: 1,
        }
    }

    setMenuId(num) {
        this.setState({ data: num });
    }
    

    render() {
        return (
            <div class="main detail">
                <div class="header">
                    <h1 class="extraspace">Předsudky vs. fakta</h1>
                    <Link to="/">Zpět</Link>
                </div>
                <div id="facts" class="mbody">
                    <div id="factsNavBig">
                        <h2>Pořízení a&nbsp;náklady</h2>
                        <ul>
                            <MenuItem href="facts#f01" text="Elektromobil se finančně nevyplatí.  " />
                            <MenuItem href="facts#f02" text="Elektromobil vlastně není čistě ekologický.  " />
                            <MenuItem href="facts#f03" text="Elektromobily nejsou spolehlivé. " />
                            <MenuItem href="facts#f04" text="Elektromobily jsou náchylné na&nbsp;zásah bleskem.  " />
                            <MenuItem href="facts#f05" text="Vzplanutí u&nbsp;elektromobilů.  " />
                            <MenuItem href="facts#f06" text="Elektromobily svou tichostí ohrožují chodce &#8209;&nbsp;neslyší je přijíždět. " />
                        </ul>

                        <h2>Baterie a&nbsp;nabíjení</h2>
                        <ul>
                            <MenuItem href="facts#f07" text="Nabíjecích stanic je málo. " />
                            <MenuItem href="facts#f08" text="Nemůžu si elektromobil pořídit &#8209;&nbsp;nemám garáž, kde ho mohu nabíjet. " />
                            <MenuItem href="facts#f09" text="Když parkuji na ulici a&nbsp;mrzne, auto se mi vybije.  " />
                            <MenuItem href="facts#f10" text="Nabíjení trvá moc dlouho.  " />
                            <MenuItem href="facts#f11" text="Baterie po&nbsp;konci své životnosti zatíží životní prostředí. " />
                        </ul>

                        <h2>Provoz a&nbsp;jízda</h2>
                        <ul>
                            <MenuItem href="facts#f12" text="Dojezd elektromobilu je omezený, moc daleko nedojedu.  " />
                            <MenuItem href="facts#f13" text="Budu muset pořád přemýšlet, kde a&nbsp;kdy budu auto nabíjet.  " />
                            <MenuItem href="facts#f14" text="Je nebezpečné elektromobilem jet či ho nabíjet za&nbsp;deště. " />
                        </ul>
                    </div>

                </div>
            </div>
        );
    }
}
