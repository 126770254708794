import { Charging } from "./components/Charging";
import { Fuel } from "./components/Fuel";
import { Tarif } from "./components/Tarif";
import { ChargeMap } from "./components/ChargeMap";
import { Range } from "./components/Range";
import { Home } from "./components/Home";
import { Facts } from "./components/Facts";
import { FactsMenu } from "./components/FactsMenu";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/charging',
    element: <Charging />
  },
  {
    path: '/fuel',
    element: <Fuel />
    },
    {
        path: '/tarif',
        element: <Tarif />
    },
    {
        path: '/chargemap',
        element: <ChargeMap />
    },
    {
        path: '/range',
        element: <Range />
    },
    {
        path: '/facts',
        element: <Facts />
    },
    {
        path: '/factsmenu',
        element: <FactsMenu />
    }

];

export default AppRoutes;
