import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class MenuItem extends Component {
    constructor(props) {
        super(props);
        this.toggleClass = this.toggleClass.bind(this);
    }
    toggleClass() {
        this.props.onChange();
    };

    render() {
        return (
            <li className={this.props.menuId == this.props.myId ? 'active factMenu' : 'factMenu'} ><a href={this.props.href} onClick={() => { this.toggleClass(); } }>{this.props.text } </a></li>
        )
    }
}
class DisplayItem extends Component {
    constructor(props) {
        super(props);
        this.toggleClass = this.toggleClass.bind(this);
    }
  
    toggleClass() {
        this.props.onChange();
    };

    render() {
        return (
            <div className={this.props.menuId == this.props.myId ? 'active factCont' : 'factCont'} onClick={() => { this.toggleClass(); }}>
                <div class="anch"  id={this.props.id}></div><h3> {this.props.title}</h3>
                { this.props.children }
            </div>
       )
    }
}
export class Facts extends Component {
    static displayName = Facts.name;

    constructor(props) {
        super(props);
        this.state = {
            data: 1,
        }
    }

    setMenuId(num)
    {
        this.setState({ data: num });
    }
    

  render() {
      return (
          <div class="main detail">
          <div class="header">
            <h1 class="extraspace">Předsudky vs. fakta</h1>
            <Link  to="/">Zpět</Link>
          </div>
              <div id="facts" class="mbody">
            <div id="factsNav">
            <h2>Pořízení a&nbsp;náklady</h2>
                      <ul>
                          <MenuItem href="facts#f01" menuId={ this.state.data } myId="1" onChange={() => this.setMenuId(1) } text="Elektromobil se finančně nevyplatí.  " />
                          <MenuItem href="facts#f02" menuId={ this.state.data } myId="2" onChange={() => this.setMenuId(2) } text="Elektromobil vlastně není čistě ekologický.  " />
                          <MenuItem href="facts#f03" menuId={ this.state.data } myId="3" onChange={() => this.setMenuId(3) } text="Elektromobily nejsou spolehlivé. " />
                          <MenuItem href="facts#f04" menuId={ this.state.data } myId="4" onChange={() => this.setMenuId(4) } text="Elektromobily jsou náchylné na&nbsp;zásah bleskem.  " />
                          <MenuItem href="facts#f05" menuId={this.state.data} myId="5" onChange={() => this.setMenuId(5) } text="Vzplanutí u&nbsp;elektromobilů.  " />
                          <MenuItem href="facts#f06" menuId={this.state.data} myId="6" onChange={() => this.setMenuId(6)} text="Elektromobily svou tichostí ohrožují chodce &#8209;&nbsp;neslyší je přijíždět. " />
                    </ul>
            
            <h2>Baterie a&nbsp;nabíjení</h2>
        <ul>
                          <MenuItem href="facts#f07" menuId={ this.state.data } myId="7" onChange={() => this.setMenuId(7) } text="Nabíjecích stanic je málo. " />
                          <MenuItem href="facts#f08" menuId={ this.state.data } myId="8" onChange={() => this.setMenuId(8) } text="Nemůžu si elektromobil pořídit &#8209;&nbsp;nemám garáž, kde ho mohu nabíjet. " />
                          <MenuItem href="facts#f09" menuId={ this.state.data } myId="9" onChange={() => this.setMenuId(9) } text="Když parkuji na ulici a&nbsp;mrzne, auto se mi vybije.  " />
                          <MenuItem href="facts#f10" menuId={this.state.data} myId="10" onChange={() => this.setMenuId(10) } text="Nabíjení trvá moc dlouho.  " />
                          <MenuItem href="facts#f11" menuId={this.state.data} myId="11" onChange={() => this.setMenuId(11)} text="Baterie po&nbsp;konci své životnosti zatíží životní prostředí. " />
            </ul>

            <h2>Provoz a&nbsp;jízda</h2>
                        <ul>
                          <MenuItem href="facts#f12" menuId={ this.state.data } myId="12" onChange={() => this.setMenuId(12) } text="Dojezd elektromobilu je omezený, moc daleko nedojedu.  " />
                          <MenuItem href="facts#f13" menuId={this.state.data} myId="13" onChange={() => this.setMenuId(13) } text="Budu muset pořád přemýšlet, kde a&nbsp;kdy budu auto nabíjet.  " />
                          <MenuItem href="facts#f14" menuId={this.state.data} myId="14" onChange={() => this.setMenuId(14)} text="Je nebezpečné elektromobilem jet či ho nabíjet za&nbsp;deště. " />
                    </ul>
            </div>
        <div id="factsInfo">

                      <h2>Pořízení a&nbsp;náklady</h2>

                     
                      <DisplayItem myId="1" menuId={this.state.data} id="f01" onChange={() => this.setMenuId(1)} title="Elektromobil se finančně nevyplatí.">
        <p>Nejdražší součást elektromobilu je jeho baterie. S&nbsp;rostoucím počtem vyrobených baterií se však ceny postupně snižují. Navíc při provozu elektromobilu dosahujete úspor – elektřina potřebná k&nbsp;provozu je zpravidla, při standardních podmínkách, levnější než odpovídající množství benzinu či nafty a&nbsp;vozy jsou také méně náročné na servis díky nižšímu množství součástek. S&nbsp;elektromobilem se vám navíc nabízí některá zvýhodnění, jako je možnost jízdy na dálnici bez dálniční známky či zvýhodněné parkování v&nbsp;rezidenčních lokalitách měst. </p>
          </DisplayItem>
                      <DisplayItem myId="2" menuId={this.state.data} id="f02" onChange={() => this.setMenuId(2)} title="Elektromobil vlastně není čistě ekologický.">
        <p>Zásadní výhodou elektromobilů je, že při jízdě neprodukují žádné emise v&nbsp;místě, kde se pohybují. </p>
        <p>Ve ŠKODA AUTO myslíme i&nbsp;na recyklaci baterií. Po použití ve voze dostanou „druhý život“ v&nbsp;chytrých energetických úložištích ŠKODA&nbsp;iV. Toto opatření prodlužuje život baterií a&nbsp;zlepšuje tak jejich ekologickou stopu. </p>

        <p>Automobily ŠKODA jsou v&nbsp;souladu s&nbsp;evropskými směrnicemi také nejen minimálně z&nbsp;85&nbsp;% jejich hmotnosti recyklovatelné, ale z&nbsp;dalších 10&nbsp;% jsou dále energeticky využitelné, tudíž odpad putující na skládky představuje pouze cca 5&nbsp;% hmotnosti vozu, přičemž i&nbsp;toto číslo se snažíme nadále snižovat. </p>

        <p>ALE: K&nbsp;objektivnímu vyhodnocení vlivu elektromobilů na životní prostředí planety Země je nezbytné brát v&nbsp;úvahu celkovou uhlíkovou stopu elektromobilu od jeho výroby, výroby baterie, po zdroj elektrické energie a&nbsp;likvidaci na konci životnosti. ŠKODA AUTO je výrobce elektromobilů, ne výrobce elektřiny. Aby reálně došlo k&nbsp;redukci emisí CO<sub>2</sub>, je nezbytné usilovat o&nbsp;trvalou udržitelnost výroby elektřiny, která by měla být v&nbsp;ideálním případě také bezemisní. </p>
          </DisplayItem>
                      <DisplayItem myId="3" menuId={this.state.data} id="f03" onChange={() => this.setMenuId(3)} title="Elektromobily nejsou spolehlivé.">

        <p>Stejně jako všechna naše další vozidla, tak i&nbsp;elektromobily značky ŠKODA jsou vysoce spolehlivé. Vynakládáme velké úsilí na to, abychom vyvíjeli a&nbsp;vyráběli co nejkvalitnější vozy, a&nbsp;to za použití co nejspolehlivějších součástek. Motor elektromobilu má mnohem méně komponent než motor spalovacího vozu, čili také méně součástek, které se mohou porouchat nebo opotřebit. </p>

          </DisplayItem>
                      <DisplayItem myId="4" menuId={this.state.data} id="f04" onChange={() => this.setMenuId(4)} title="Elektromobily jsou náchylné na zásah bleskem.">
        <p>V případě zásahu bleskem se elektromobily chovají zcela stejně jako vozy se spalovacím motorem. A&nbsp;v případě, že je elektromobil v&nbsp;danou chvíli nabíjen, dojde k&nbsp;vyhození pojistky a&nbsp;nabíjení se přeruší. </p>

          </DisplayItem>
                      <DisplayItem myId="5" menuId={this.state.data} id="f05" onChange={() => this.setMenuId(5)} title="Vzplanutí u&nbsp;elektromobilů.">
        <p>Elektromobily a&nbsp;hybridy nelze kategorizovat jako více nebezpečné než vozy se spalovacím motorem. Jejich likvidace je náročnější a&nbsp;vyžaduje speciální postupy. Ve ŠKODA AUTO dbáme na maximální bezpečnost všech našich vozů. </p>

        <p>Baterie samy o&nbsp;sobě nepředstavují vyšší riziko vzniku požáru v&nbsp;porovnání se spalovacími motory. Naopak, většina požárů automobilů je způsobena únikem pohonných hmot, které se kvůli vysokým teplotám na jejich povrchu vznítí. V&nbsp;elektromobilech tyto kapaliny chybí. Pokud by přeci k&nbsp;vzplanutí došlo, hasiči ŠKODA AUTO společně s&nbsp;Hasičským záchranným sborem vyvinuli metodu hašení, která hořící trakční baterii uhasí do několika minut. Využívá se speciálně upravené vysokotlaké zařízení CCS Cobra a&nbsp;nově vyvinuté hasivo, které je určeno na hašení trakčních baterií. </p>
          </DisplayItem>
                      <DisplayItem myId="6" menuId={this.state.data} id="f06" onChange={() => this.setMenuId(6)} title="Elektromobily svou tichostí ohrožují chodce – neslyší je přijíždět.">
        <p>Při nízkých rychlostech jsou elektromobily skutečně velmi tiché. Na to však myslíme my i&nbsp;zákonodárci. Vozy jsou proto vybaveny zařízením AVAS (Acoustic Vehicle Alerting System), které vytváří specifický zvuk. Při vyšších rychlostech je již zvuk vytvářen pneumatikami či odporem vzduchu, proto ho poté není nutné vytvářet uměle. </p>

          </DisplayItem>
         <h2>Baterie a&nbsp;nabíjení</h2>
                      <DisplayItem myId="7" menuId={this.state.data} id="f07" onChange={() => this.setMenuId(7)} title="Nabíjecích stanic je málo.">
        <p>Počet veřejných nabíjecích stanic je nižší, než počet čerpacích stanic. Na rozdíl od tradičních benzinek však můžete elektromobil nabíjet i&nbsp;doma, případně během nakupování, kdy vůz zpravidla stojí bez užitku. S&nbsp;hledáním nabíjecích stanic vám navíc pomůže mobilní aplikace ŠKODA Connect, kterou si můžete vyzkoušet na interaktivním panelu v&nbsp;expozici. </p>
        <p>Jako největší prodejce elektromobilů na českém trhu považujeme za důležité, aby výstavba infrastruktury pro veřejné dobíjení i&nbsp;nadále držela krok s&nbsp;dynamickým růstem počtu elektromobilů na českých silnicích. V&nbsp;souladu se strategickými cíli Národního akčního plánu čisté mobility by na českém trhu mělo být vybudováno 35 000 dobíjecích bodů do roku 2030. </p>
          </DisplayItem>

                      <DisplayItem myId="8" menuId={this.state.data} id="f08" onChange={() => this.setMenuId(8)} title="Nemůžu si elektromobil pořídit – nemám garáž, kde ho mohu nabíjet.">
        <p>Pokud bydlíte například v&nbsp;činžovním nebo panelovém domě a&nbsp;nemáte možnost nechat vůz nabíjet přes noc ve vlastní garáži rodinného domu, stále máte několik možností, jak nabíjení řešit. </p>

        <p>Nové bytové projekty už s&nbsp;elektromobilitou počítají – buď je možnost zřídit si nabíjecí místo v&nbsp;podzemních garážích, nebo je parkovací místo pro nabíjení už připraveno. V&nbsp;městských ulicích existují například tzv. chytré nabíjecí lampy a&nbsp;na sídlištích zase chytré trafostanice, u&nbsp;kterých můžete večer zaparkovat a&nbsp;nechat auto přes noc nabít. </p>

        <p>Nabíjení elektrovozů je na zaměstnaneckých a&nbsp;návštěvnických parkovištích ŠKODA AUTO v&nbsp;současné době poskytováno zdarma. Obsluha dobíjecích stanic je velmi jednoduchá. Po zaparkování u&nbsp;dobíjecí stanice stačí pouze zapojit konektor do vozu a&nbsp;na centrálním panelu vybrat číslo konektoru, který budete používat. Následně se autentifikujete/přihlásíte svým MFA průkazem na čtečce dobíjecí stanice, a&nbsp;tím se proces nabíjení automaticky zahájí. V&nbsp;současné chvíli je dostupných celkem 218 nabíjecích bodů na zaměstnaneckých a&nbsp;návštěvnických parkovištích (Mladá Boleslav, Kvasiny, Vrchlabí) a&nbsp;dále společně se ŠKO-ENERGEM máme dalších 32 bodů na veřejných místech v&nbsp;Mladé Boleslavi. </p>
          </DisplayItem>
                      <DisplayItem myId="9" menuId={this.state.data} id="f09" onChange={() => this.setMenuId(9)} title="Když parkuji na ulici a&nbsp;mrzne, auto se mi vybije.">
        <p>Záleží na míře nabití baterie a&nbsp;venkovní teplotě. Zpravidla se však dá říct, že pokud je baterie plně nabitá, vůz může v&nbsp;nízkých venkovních teplotách zůstat stát i&nbsp;několik měsíců. </p>

          </DisplayItem>
                      <DisplayItem myId="10" menuId={this.state.data} id="f10" onChange={() => this.setMenuId(10)} title="Nabíjení trvá moc dlouho.">
          <p>Nejdéle trvá nabíjení z&nbsp;domácí síťové zásuvky, ovšem zde je předpoklad, že můžete nechat vůz nabíjet přes noc. Domácí nabíjení lze případně také urychlit instalací wallboxu. Nabíjení z&nbsp;veřejných nabíjecích stanic zpravidla trvá kratší dobu díky vyššímu nabíjecímu výkonu. Je dobré mít také na paměti, že baterii nesvědčí nabíjení vždy na&nbsp;100&nbsp;% kapacity, postačí ji proto nabít zhruba na 80&nbsp;%. To Vám vystačí na řádově stovky kilometrů dojezdu a&nbsp;zkrátí čas nabíjení. </p>
          </DisplayItem>
                      <DisplayItem myId="11" menuId={this.state.data} id="f11" onChange={() => this.setMenuId(11)} title="Baterie po konci své životnosti zatíží životní prostředí.">
        <p>Když už baterie na provoz elektromobilu nestačí, neznamená to, že musí být nutně na odpis. Dokáže ještě řadu let sloužit v&nbsp;méně náročném prostředí, například pro ukládání elektrické energie v&nbsp;domácnosti (obzvlášť pokud používáte solární panely), v&nbsp;servisních centrech či továrnách. V&nbsp;případě, že již není použitelná ani tímto způsobem, je z&nbsp;96&nbsp;% recyklovatelná a&nbsp;my ve ŠKODA AUTO neustále pracujeme na zvyšování tohoto čísla. </p>
    </DisplayItem>

            <h2>Provoz a&nbsp;jízda</h2>
                      <DisplayItem myId="12" menuId={this.state.data} id="f12" onChange={() => this.setMenuId(12)} title="Dojezd elektromobilu je omezený, moc daleko nedojedu.">
        <p>Baterie, jejich efektivní využití a&nbsp;související technologie se neustále zlepšují a&nbsp;tím roste i&nbsp;maximální dojezd elektromobilů. Dojezd modelu ENYAQ COUPÉ&nbsp;iV se aktuálně pohybuje na 545&nbsp;km v&nbsp;závislosti na vnějších podmínkách. Trasy delší, než je udávaný dojezd elektromobilu, můžete plánovat tak, aby vaše pauza na odpočinek vyšla na místo s&nbsp;rychlonabíjecí stanicí. Velká část vozového parku denně neujede více než 100&nbsp;km (např.&nbsp;v&nbsp;režimu dojíždění do práce). V&nbsp;takovém režimu by postačilo nabíjet vozidlo 1x až&nbsp;2x týdně. </p>

          </DisplayItem>
                      <DisplayItem myId="13" menuId={this.state.data} id="f13" onChange={() => this.setMenuId(13)} title="Budu muset pořád přemýšlet, kde a&nbsp;kdy budu auto nabíjet.">
          <p>V ideálním případě budete svůj elektromobil nabíjet přes noc doma nebo přes den v&nbsp;práci. To je zpravidla nejlevnější přístup a&nbsp;také nejšetrnější k&nbsp;samotné baterii. V případě potřeby nabíjení mimo domov existuje množství veřejných nabíjecích stanic a&nbsp;jejich počet neustále roste. Ty jsou navíc zpravidla na místech, kde je využijete nejvíce: v&nbsp;parkovacích domech, v&nbsp;nákupních centrech a&nbsp;podobně. S&nbsp;kartou POWERPASS navíc získáte jednoduché řešení s&nbsp;přístupem až k&nbsp;310&nbsp;000 nabíjecích bodů. </p >
          </DisplayItem>
                      <DisplayItem myId="14" menuId={this.state.data} id="f14" onChange={() => this.setMenuId(14)} title="Je nebezpečné elektromobilem jet či ho nabíjet za deště.">
            <p>Baterie je dobře izolována, takže elektromobil můžete provozovat úplně stejně, jako vůz se spalovacím motorem. Stejně tak se nemusíte obávat nabíjení &#8209;&nbsp;všechny součásti jsou dobře izolovány. Pokud vůz přeci jen rozpozná nějakou komplikaci, nabíjení se nespustí. </p>
        <p>Doporučujeme důkladné prostudování uživatelské příručky a&nbsp;dodržování uvedených pravidel. </p>
          </DisplayItem>
           </div>

              </div>
          </div>
    );
  }
}
