import WidgetLoader from '@skodaflow/widget-loader';
import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import * as MaterialUI from '@mui/material';
import * as MaterialUIStyles from '@mui/material/styles';
import * as EmotionReact from '@emotion/react';
import * as EmotionStyled from '@emotion/styled';
import { Link } from 'react-router-dom';

export class Range extends Component {
    static displayName = Range.name;

  render() {
      return (
          <div class="main detail">
          <div class="header">
          <h1>Kalkulačka dojezdu</h1>
          <Link to="/">Zpět</Link>
          </div>
              <div class="mbody">
            <WidgetLoader module={'RangeCalculator'}
                      //source={'https://skoda-range-calculator-dev.azurewebsites.net/widgets/range-calculator-v2.min.js'}
                      source={'https://rangecalculator.skoda-auto.com/widgets/range-calculator-v2.min.js'}
                
                      options={{
                          //endpoint: "https://skoda-range-calculator-dev.azurewebsites.net", bid: "260", culture: "cs-CZ",
                          endpoint: "https://rangecalculator.skoda-auto.com", bid: "260", culture: "cs-CZ",
                          settings: { disableTracking: true, disableBanner: false, disableEmobilityNavigation: true
                    }, }}
                loadingMessage={(<div class="loading">Nahrávám data, prosím čekejte...</div>)}
                deps={{ React, ReactDOM, MaterialUI, MaterialUIStyles, EmotionReact, EmotionStyled }}
                />

              </div>
    </div>
    );
  }
}
