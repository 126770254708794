import WidgetLoader from '@skodaflow/widget-loader';
import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import * as MaterialUI from '@mui/material';
import * as MaterialUIStyles from '@mui/material/styles';
import * as EmotionReact from '@emotion/react';
import * as EmotionStyled from '@emotion/styled';
import { Link } from 'react-router-dom';

export class Charging extends Component {
    static displayName = Charging.name;

  render() {
      return (
          <div class="main detail">
          <div class="header">
          <h1>Kalkulačka dobíjení</h1>
          <Link to="/">Zpět</Link>
          </div>
      <div class="mbody">
      
         <WidgetLoader module={ 'ChargingCalculator'}
                      //source={'https://skoda-charging-calculator-dev.azurewebsites.net/widgets/charging-calculator-v2.min.js'}
                      source={'https://chargingcalculator.skoda-auto.com/widgets/charging-calculator-v2.min.js'}
                      options={{
                          //endpoint: "https://skoda-charging-calculator-dev.azurewebsites.net", bid: "260", culture: "cs-CZ",
                          endpoint: "https://chargingcalculator.skoda-auto.com", bid: "260", culture: "cs-CZ",
                          settings: { disableTracking: true, disableBanner: false, disableEmobilityNavigation: true },
                      }}
                      loadingMessage={(<div class="loading">Nahrávám data, prosím čekejte...</div>)}
                      deps={{ React, ReactDOM, MaterialUI, MaterialUIStyles, EmotionReact, EmotionStyled }}
                />

              </div>
    </div>
    );
  }
}
