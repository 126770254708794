import WidgetLoader from '@skodaflow/widget-loader';
import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import * as MaterialUI from '@mui/material';
import * as MaterialUIStyles from '@mui/material/styles';
import * as EmotionReact from '@emotion/react';
import * as EmotionStyled from '@emotion/styled';
import { Link } from 'react-router-dom';

export class Fuel extends Component {
    static displayName = Fuel.name;

  render() {
      return (
          <div class="main detail">
          <div class="header">
          <h1>Kalkulačka úspor</h1>
          <Link to="/">Zpět</Link>
          </div>
              <div class="mbody">
      
            <WidgetLoader module={'FuelSavingsCalculator'}
                        //source={'https://skoda-fuel-savings-calculator-dev.azurewebsites.net/widgets/fuel-savings-calculator-v2.min.js'}
                        source={'https://skoda-fuel-savings-calculator.azurewebsites.net/widgets/fuel-savings-calculator-v2.min.js'}
                        deps={{ React, ReactDOM, MaterialUI, MaterialUIStyles, EmotionReact, EmotionStyled }}
                        options={{
                            //endpoint: "https://skoda-fuel-savings-calculator-dev.azurewebsites.net", bid: "260", culture: "cs-CZ",
                            endpoint: "https://skoda-fuel-savings-calculator.azurewebsites.net", bid: "260", culture: "cs-CZ",
                            settings: { disableTracking: true, disableBanner: false, disableEmobilityNavigation: true },
                        }}
                        loadingMessage={(<div class="loading">Nahrávám data, prosím čekejte...</div>)}
                    />
            

              </div>
    </div>
    );
  }
}
