import WidgetLoader from '@skodaflow/widget-loader';
import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import * as MaterialUI from '@mui/material';
import * as MaterialUIStyles from '@mui/material/styles';
import * as EmotionReact from '@emotion/react';
import * as EmotionStyled from '@emotion/styled';
import { Link } from 'react-router-dom';

export class ChargeMap extends Component {
    static displayName = ChargeMap.name;

  render() {
      return (
          <div class="main detail">
          <div class="header">
          <h1>Mapa nabíjecích míst</h1>
          <Link to="/">Zpět</Link>
          </div>
              <div class="mbody">
                  <WidgetLoader module={'ChargeMap'}
                      //source='https://skoda-chargemap-dev.azurewebsites.net/widgets/chargemap-v2.min.js'
                      source={'https://chargemap.skoda-auto.com/widgets/chargemap-v2.min.js'}
                        deps={{ React, ReactDOM, MaterialUI, MaterialUIStyles, EmotionReact, EmotionStyled }}
                        options={{
                            //endpoint: 'https://skoda-chargemap-dev.azurewebsites.net', bid: '260', culture: 'cs-CZ',
                            endpoint: 'https://chargemap.skoda-auto.com', bid: '260', culture: 'cs-CZ',
                            settings: { disableTracking: true, disableBanner: false, disableEmobilityNavigation: true, height: 1000, collapseDisclaimer: true }
                      }}
loadingMessage={(<div class="loading">Nahrávám data, prosím čekejte...</div>)}
                />
              </div>
    </div>
    );
  }
}
