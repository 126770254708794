import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div id="home" class="main">
      
      <ul className="menu">
                <li><Link tag={Link} className="text-dark" to="/facts"><img src="./assets/images/bt1.png" alt="Předsudky vs. fakta" /></Link></li>
                <li><Link tag={Link} className="text-dark" to="/charging"><img src="./assets/images/bt2.png" alt="Kalkulačka dobíjení" /></Link></li>
                <li><Link tag={Link} className="text-dark" to="/fuel"><img src="./assets/images/bt3.png" alt="Kalkulačka úspoer" /></Link></li>
                <li><Link tag={Link} className="text-dark" to="/chargemap"><img src="./assets/images/bt6.png" alt="Mapa dobíjecích míst" /></Link></li>
                <li><Link tag={Link} className="text-dark" to="/range"><img src="./assets/images/bt5.png" alt="Kalkulačka dojezdu" /></Link></li>
                
        </ul>

    </div>
    );
  }
}
